/**
 * @ComponentFor ProductListBlockViewModel
 */
import React from 'react';
import { styled, StyledProps, applyClassName } from '@glitz/react';
import { EpiProperty, epiPropertyValue } from '@avensia/scope-episerver';
import Products, { Layout } from 'Product/ProductCard/List';
import padStyled from 'Shared/Blocks/PaddedBlock';
import { delta, minMediumMediaQuery, pixelsToUnit, beta, large, thin, truncate, kappa } from 'Shared/Style';
import ProductListBlockViewModelType from './ProductListBlockViewModel.type';
import Link from 'Shared/Link';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';
import { Style } from '@glitz/type';

type PropType = ProductListBlockViewModelType & StyledProps;

const Header = styled.div({
  marginBottom: large,
  position: 'relative',
  textAlign: 'center',
  [minMediumMediaQuery]: {
    marginBottom: pixelsToUnit(10),
    display: 'flex',
  },
});

const LinkStyled = styled(Link, {
  color: (theme) => theme.textColor,
  display: 'inline-block',
  letterSpacing: pixelsToUnit(0.5),
  maxWidth: '100%',
  textTransform: 'uppercase',
  position: 'static',
  [minMediumMediaQuery]: {
    maxWidth: '25%',
    fontSize: kappa,
  },
  ':hover': {
    borderBottom: {
      width: thin,
      color: (theme) => theme.textColor,
      style: 'solid',
    },
  },
  ...truncate(),
});

const Title = styled(
  applyClassName(({ compose, ...restProps }: React.HTMLProps<HTMLHeadingElement> & StyledProps) => (
    <h2 {...restProps} />
  )),
  {
    fontSize: delta,
    fontWeight: 'bold',
    letterSpacing: pixelsToUnit(1.9),
    lineHeight: 'normal',
    marginBottom: pixelsToUnit(25),
    textAlign: 'center',
    textTransform: 'uppercase',
    [minMediumMediaQuery]: {
      fontSize: beta,
      fontWeight: 'normal',
      letterSpacing: pixelsToUnit(3),
      marginBottom: pixelsToUnit(51),
    },
  },
);

const HeaderTitle = styled(Title, {
  marginBottom: 0,
  [minMediumMediaQuery]: {
    marginBottom: 0,
  },
});

const HeaderTitleWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const LinkWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: pixelsToUnit(5),
  [minMediumMediaQuery]: {
    justifyContent: 'flex-end',
    paddingRight: pixelsToUnit(10),
    marginBottom: pixelsToUnit(15),
  },
});

const ProductsBlock = padStyled((props: PropType) => {
  const headerUrl = epiPropertyValue(props.block.linkUrl);
  const horizontalScrollMobile = epiPropertyValue(props.block.horizontalScrollMobile);
  const scrollStyle: Style = {
    overflow: 'auto',
    flexWrap: 'nowrap',
    justifyContent: 'left',
    padding: { xy: 15 },
    margin: { x: -15 },
    [minMediumMediaQuery]: {
      overflow: 'unset',
      flexWrap: 'unset',
      justifyContent: 'unset',
      padding: 0,
      margin: 0,
    },
  };
  return (
    <styled.Div css={props.compose()} className={BOX_ITEM_CLASSNAME}>
      {!!headerUrl ? (
        <>
          <Header>
            <HeaderTitleWrapper>
              <EpiProperty component={HeaderTitle} for={props.block.title} />
            </HeaderTitleWrapper>
          </Header>
          <LinkWrapper>
            <EpiProperty
              /* tslint:disable-next-line:jsx-no-lambda */
              component={(innerProps) => <LinkStyled to={headerUrl} {...innerProps} />}
              for={props.block.linkText}
            />
          </LinkWrapper>
        </>
      ) : (
        <EpiProperty component={Title} for={props.block.title} />
      )}
      <Products
        css={horizontalScrollMobile && scrollStyle}
        products={props.products}
        layout={Layout.Block}
        margin={horizontalScrollMobile}
        noPlaceholders={horizontalScrollMobile}
      />
    </styled.Div>
  );
});

export default ProductsBlock;
